<template>
  <div id="app" class="small-container">
    <TabCard :tabs="tabs" :initial-tab="initialTab">
      <template slot="tab-head-data">
        Data
      </template>
      <template slot="tab-panel-data">
        <weather-data :weather-data="weatherData" @get:weather-data="getWeatherData" />
      </template>
      <template slot="tab-head-network">
        Network
      </template>
      <template slot="tab-panel-network">
        <h2>Network</h2>
      </template>
    </TabCard>
  </div>
</template>

<script>
import TabCard from '@/components/TabCard.vue'
import WeatherData from '@/components/WeatherData.vue'

export default {
 /* eslint-disable no-console */
 /* eslint-disable no-alert */

  name: 'App',
  components: {
    TabCard, WeatherData
  },
  data() {
    return {
      initialTab: 'data',
      tabs: ['data', 'network'],
      weatherData: {}
    }
  },
  created() {
    this.getWeatherData()
  },
  methods: {
    async getWeatherData() {
      try {
        const response = await fetch('https://ext.temp.silicia.be/weather')
        const data = await response.json()
        if (response.ok && data != {}) {
          this.weatherData = data.weather
        } else {
          console.log('getWeatherData failed!')
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style>
  button {
    background: #009435;
    border: 1px solid #009435;
  }

  .small-container {
    max-width: 680px;
  }
</style>

