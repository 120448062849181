<template>
  <div id="weather-chart">
    <highcharts :options="chartOptions" :updateArgs="updateArguments"></highcharts>
  </div>
</template>

<script>
 /* eslint-disable no-console */

  export default {
    name: 'weather-chart',
    props: {
      weatherData: Object,
    },
    data() {
      return {
        colors: ['#ffa600', '#bc5090', '#003f5c']
      }
    },
    computed: {
      updateArguments() {
        return [true, true, {duration: 1000}]
      },
      chartOptions() {
        if (this.weatherData.timeline === undefined) {
          return {}
        }
        return {
          chart: {type: 'spline'},
          title: {text: undefined},
          tooltip: {xDateFormat: '%d.%m.%Y %H:%M', shared: false},
          series: this.weatherData.series.map((serie, index) => {
            return {
              name: serie.name,
              color: this.colors[index],
              tooltip: {valueSuffix: serie.unit},
              yAxis: index,
              data: serie.data.map((value, index) => [this.getTime(this.weatherData.timeline[index]), value])
            }
          }),
          xAxis: {
            type: 'datetime',
            labels: {format: '{value:%d.%m.}'},
            units: [['day', [1]]]
          },
          yAxis: this.weatherData.series.map((serie, index) => {
            return {title: {text: serie.unit}, opposite: (index % 2 === 1)}
          })  
        }
      }
    },
    methods: {
      getTime(timestamp) {
        return (new Date(timestamp)).getTime() - (new Date().getTimezoneOffset())*60*1000
      }
    }
  }
</script>

<style scoped></style>

