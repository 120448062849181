<template>
  <div id="weather-data">
    <weather-chart :weather-data="weatherData" />
    <button @click="$emit('get:weather-data')">Get Weather Data</button>
    <weather-table :weather-data="weatherData" />
  </div>
</template>

<script>
  import WeatherChart from '@/components/WeatherChart.vue'
  import WeatherTable from '@/components/WeatherTable.vue'

  export default {
    name: 'weather-data',
    components: {
      WeatherChart, WeatherTable
    },
    props: {
      weatherData: Object,
    }
  }
</script>

<style scoped></style>

