<template>
  <div id="weather-data">
    <table>
      <thead>
        <tr>
          <th v-for="(header, index) in data.headers" :key="index">{{header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in data.values" :key="row[0]">
          <td v-for="(value, index) in row" :key="index">{{value}}{{data.units[index]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'weather-table',
    props: {
      weatherData: Object,
    },
    computed: {
      data() {
        var data = {headers: [], units: [], values:[]}
        if (this.weatherData.timeline) {
          data.headers.push('Timestamp')
          data.units.push('')
          this.weatherData.series.forEach(serie => {
            data.headers.push(serie.name)
            data.units.push(serie.unit)
          })
          data.values = this.weatherData.timeline.map((value, index) => {
            var row = [new Date(value).toLocaleString('de-CH')]
            this.weatherData.series.forEach(serie => {row.push(serie.data[index])})
            return row
          }).sort((a, b) => (a[0] < b[0]) ? 1 : -1)
        }
        return data
      }
    }
  }
</script>

<style scoped></style>

